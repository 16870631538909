// Generated by Framer (f318921)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-qChlX"

const variantClassNames = {WBRaqLyjs: "framer-v-qijx81"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({background, height, id, link, newTab, title, width, ...props}) => { return {...props, AA6g9YvoW: link ?? props.AA6g9YvoW, getd5F31A: newTab ?? props.getd5F31A, MTEDEb6Nf: title ?? props.MTEDEb6Nf ?? "Contact me", NYkpg6Jmt: background ?? props.NYkpg6Jmt ?? "rgb(17, 16, 17)"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;link?: string;background?: string;newTab?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, MTEDEb6Nf, AA6g9YvoW, NYkpg6Jmt, getd5F31A, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "WBRaqLyjs", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={AA6g9YvoW} nodeId={"WBRaqLyjs"} openInNewTab={getd5F31A}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-qijx81", className, classNames)} framer-1o383ss`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"WBRaqLyjs"} ref={ref ?? ref1} style={{backgroundColor: NYkpg6Jmt, borderBottomLeftRadius: 24, borderBottomRightRadius: 24, borderTopLeftRadius: 24, borderTopRightRadius: 24, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "RlM7U2F0b3NoaS1tZWRpdW0=", "--framer-font-family": "\"Satoshi\", \"Satoshi Placeholder\", sans-serif", "--framer-font-weight": "500", "--framer-line-height": "24px", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Get Custom Solution</motion.p></React.Fragment>} className={"framer-1dzv1qw"} data-framer-name={"Text"} fonts={["FS;Satoshi-medium"]} layoutDependency={layoutDependency} layoutId={"MTHnv9LgT"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "16px"}} text={MTEDEb6Nf} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-qChlX.framer-1o383ss, .framer-qChlX .framer-1o383ss { display: block; }", ".framer-qChlX.framer-qijx81 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 5px; height: min-content; justify-content: center; overflow: hidden; padding: 12px 24px 12px 24px; position: relative; text-decoration: none; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-qChlX .framer-1dzv1qw { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-qChlX.framer-qijx81 { gap: 0px; } .framer-qChlX.framer-qijx81 > * { margin: 0px; margin-left: calc(5px / 2); margin-right: calc(5px / 2); } .framer-qChlX.framer-qijx81 > :first-child { margin-left: 0px; } .framer-qChlX.framer-qijx81 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 132.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"MTEDEb6Nf":"title","AA6g9YvoW":"link","NYkpg6Jmt":"background","getd5F31A":"newTab"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerpwOpoV9LS: React.ComponentType<Props> = withCSS(Component, css, "framer-qChlX") as typeof Component;
export default FramerpwOpoV9LS;

FramerpwOpoV9LS.displayName = "Button";

FramerpwOpoV9LS.defaultProps = {height: 48, width: 132.5};

addPropertyControls(FramerpwOpoV9LS, {MTEDEb6Nf: {defaultValue: "Contact me", displayTextArea: false, title: "Title", type: ControlType.String}, AA6g9YvoW: {title: "Link", type: ControlType.Link}, NYkpg6Jmt: {defaultValue: "rgb(17, 16, 17)", title: "Background", type: ControlType.Color}, getd5F31A: {defaultValue: false, title: "New Tab", type: ControlType.Boolean}} as any)

addFonts(FramerpwOpoV9LS, [{explicitInter: true, fonts: [{family: "Satoshi", source: "fontshare", style: "normal", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/P2LQKHE6KA6ZP4AAGN72KDWMHH6ZH3TA/ZC32TK2P7FPS5GFTL46EU6KQJA24ZYDB/7AHDUZ4A7LFLVFUIFSARGIWCRQJHISQP.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})